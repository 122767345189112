import React, { useState } from 'react';
import { FaUsers, FaChartLine, FaCalendarAlt } from 'react-icons/fa';
import ProgressIndicator from './dashboard/ProgressIndicator';

interface Campaign {
  id: string;
  name: string;
  status: 'active' | 'completed' | 'draft';
  influencers: number;
  engagement: number;
  startDate: string;
}

const campaigns: Campaign[] = [
  { id: '1', name: 'Summer Collection Launch', status: 'active', influencers: 50, engagement: 3.8, startDate: '2023-06-01' },
  { id: '2', name: 'Back to School', status: 'draft', influencers: 30, engagement: 0, startDate: '2023-08-15' },
  { id: '3', name: 'Holiday Special', status: 'completed', influencers: 75, engagement: 4.2, startDate: '2022-12-01' },
];



const CampaignOverview: React.FC = () => {
    const [expandedCampaigns, setExpandedCampaigns] = useState<string[]>([]);
  
    const toggleExpand = (campaignId: string) => {
      setExpandedCampaigns(prev => 
        prev.includes(campaignId) 
          ? prev.filter(id => id !== campaignId)
          : [...prev, campaignId]
      );
    };
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {campaigns.map((campaign) => (
          <li key={campaign.id}>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-indigo-600 truncate cursor-pointer" onClick={() => toggleExpand(campaign.id)}>
                  {campaign.name}
                </p>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    campaign.status === 'active' ? 'bg-green-100 text-green-800' :
                    campaign.status === 'completed' ? 'bg-gray-100 text-gray-800' :
                    'bg-yellow-100 text-yellow-800'
                  }`}>
                    {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                  </p>
                </div>
              </div>
              <div className="mt-2 sm:flex sm:justify-between">
                <div className="sm:flex">
                  <p className="flex items-center text-sm text-gray-500">
                    <FaUsers className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                    {campaign.influencers} influencers
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                    <FaChartLine className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                    {campaign.engagement}% engagement
                  </p>
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <FaCalendarAlt className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                  <p>
                    Started on <time dateTime={campaign.startDate}>{new Date(campaign.startDate).toLocaleDateString()}</time>
                  </p>
                </div>
              </div>
            </div>
            {expandedCampaigns.includes(campaign.id) && (
              <div className="px-4 py-4 sm:px-6 bg-gray-50">
                <ProgressIndicator />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CampaignOverview;