import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ShopifyCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const shop = urlParams.get('shop');
    const accessToken = urlParams.get('accessToken');

    if (shop && accessToken) {
      // Store the data securely (consider using encrypted storage for production)
      localStorage.setItem('shopify_shop', shop);
      localStorage.setItem('shopify_access_token', accessToken);
      
      console.log('Shopify authentication completed for shop:', shop);
      navigate('/dashboard');
    } else {
      console.error('Missing Shopify data in callback');
      navigate('/', { state: { error: 'Authentication failed' } });
    }
  }, [navigate, location]);

  return <div>Processing Shopify authentication...</div>;
};

export default ShopifyCallback;