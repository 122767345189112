import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaUsers, FaTshirt, FaPercent, FaDollarSign, FaGlobeAmericas, FaInfoCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

interface CampaignData {
  creatorCount: number;
  selectedProduct: string;
  discountType: '$' | '%';
  discountValue: number;
  selectedCountry: string;
}

interface CampaignEndProps {
  campaignData: Record<string, any>;
  onBack?: () => void;
}

const CampaignEnd: React.FC<CampaignEndProps> = ({ campaignData, onBack }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [expectedSales, setExpectedSales] = useState<number>(0);
  const [expectedRevenue, setExpectedRevenue] = useState<number>(0);
  const [productCost, setProductCost] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [campaignPrice, setCampaignPrice] = useState<number>(0);

  useEffect(() => {
    const data = campaignData || location.state?.campaignData;
    if (!data) {
      navigate('/campaign-creation');
      return;
    }

    const conversionRate = 0.02; // 2% conversion rate
    const productPrice = parseFloat(data.selectedProduct?.match(/\$(\d+\.\d+)/)?.[1] || '0');
    const discountAmount = data.discountType === '%' 
      ? (productPrice * data.discountValue / 100) 
      : data.discountValue;
    const discountedPrice = productPrice - discountAmount;
    
    const sales = Math.round(data.creatorCount * conversionRate);
    const revenue = sales * discountedPrice;
    const avgRevenuePerCollab = 1040;
    const totalRevenue = revenue + (sales * avgRevenuePerCollab);

    // Estimate product cost as 50% of the original price
    const estimatedProductCost = productPrice * 0.5;
    const totalProductCost = sales * estimatedProductCost;

    const influencerContactCost = (data.creatorCount / 1000) * 1000;
    const totalCost = totalProductCost + influencerContactCost;

    const campaignProfit = totalRevenue - totalCost;

    setExpectedSales(sales);
    setExpectedRevenue(totalRevenue);
    setProductCost(totalProductCost);
    setProfit(campaignProfit);
    setCampaignPrice(influencerContactCost);
  }, [campaignData, location.state, navigate]);


  const handleBookCampaign = () => {
    console.log('Booking campaign:', { ...campaignData, expectedSales, expectedRevenue, productCost, profit, campaignPrice });
    // Navigate to a confirmation page or show a success message
  };

  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white bg-opacity-90 p-8 rounded-2xl shadow-lg w-full max-w-md card-glow"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Campaign Overview</h2>
      
      <div className="space-y-6">
        <div className="bg-indigo-50 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 text-indigo-800">Campaign Details</h3>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex items-center">
              <FaUsers className="mr-2 text-indigo-600" />
              <span className="text-sm font-medium text-gray-700">Creators: {campaignData.creatorCount}</span>
            </div>
            <div className="flex items-center">
              <FaTshirt className="mr-2 text-indigo-600" />
              <span className="text-sm font-medium text-gray-700">Product: {campaignData.selectedProduct}</span>
            </div>
            <div className="flex items-center">
              <FaPercent className="mr-2 text-indigo-600" />
              <span className="text-sm font-medium text-gray-700">Discount: {campaignData.discountValue}{campaignData.discountType}</span>
            </div>
            <div className="flex items-center">
              <FaGlobeAmericas className="mr-2 text-indigo-600" />
              <span className="text-sm font-medium text-gray-700">Country: {campaignData.selectedCountry}</span>
            </div>
          </div>
        </div>
        
        <div className="bg-green-50 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 text-green-800">Expected Performance</h3>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex items-center">
              <FaUsers className="mr-2 text-green-600" />
              <span className="text-sm font-medium text-gray-700">Estimated Sales: {expectedSales}</span>
              <FaInfoCircle className="ml-2 text-gray-400" title="Based on a 2% conversion rate" />
            </div>
            <div className="flex items-center">
              <FaDollarSign className="mr-2 text-green-600" />
              <span className="text-sm font-medium text-gray-700">Estimated Revenue: ${expectedRevenue.toFixed(2)}</span>
              <FaInfoCircle className="ml-2 text-gray-400" title="Includes $1040 average revenue per collaboration" />
            </div>
            <div className="flex items-center">
              <FaDollarSign className="mr-2 text-green-600" />
              <span className="text-sm font-medium text-gray-700">Product Costs: ${productCost.toFixed(2)}</span>
              <FaInfoCircle className="ml-2 text-gray-400" title="Estimated as 50% of the product price" />
            </div>
            <div className="flex items-center">
              <FaDollarSign className="mr-2 text-green-600" />
              <span className="text-sm font-medium text-gray-700">Estimated Profit: ${profit.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 text-center">
      <button
  onClick={() => navigate('/campaign')}
  className="w-full mb-4 flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
>
  Back to Campaign Creation
</button>
        <button
          onClick={handleBookCampaign}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transform transition hover:scale-105"
        >
          Book Campaign
        </button>
      </div>
    </motion.div>
  );
};

export default CampaignEnd;