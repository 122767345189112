import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import { FaInstagram, FaEnvelope, FaChevronDown, FaChevronUp, FaSearch, FaDollarSign, FaShoppingCart } from 'react-icons/fa';

interface Influencer {
  _id: string;
  igHandle: string;
  email: string;
  firstName: string;
  contactPerson: string;
  contacted: string;
  firstReminder: string;
  secondReminder: string;
  campaign: string;
  thread: string;
  clickUpId: string;
  clientId: string;
  revenue: number | null;
  orders: number | null;
}

const InfluencerDirectory: React.FC = () => {
  const [influencers, setInfluencers] = useState<Influencer[]>([]);
  const [sortField, setSortField] = useState<'revenue' | 'orders' | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchInfluencers = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await fetch(`http://92.113.25.172:5001/influencers/PrimalDerm?page=${page}`);
      if (!response.ok) {
        throw new Error('Failed to fetch influencers');
      }
      const data = await response.json();
      if (data.success && Array.isArray(data.influencers)) {
        setInfluencers(prevInfluencers => {
          const newInfluencers = [...prevInfluencers, ...data.influencers];
          return sortInfluencers(newInfluencers, sortField, sortDirection);
        });
        setHasMore(data.influencers.length === 100); // Assuming 100 items per page
      } else {
        console.error('Unexpected data structure:', data);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching influencers:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [page, sortField, sortDirection]);

  useEffect(() => {
    fetchInfluencers();
  }, [fetchInfluencers]);

  const sortInfluencers = (influencers: Influencer[], field: 'revenue' | 'orders' | null, direction: 'asc' | 'desc') => {
    if (!field) return influencers;
    return [...influencers].sort((a, b) => {
      const aValue = Number(a[field]) || 0;
      const bValue = Number(b[field]) || 0;
      return direction === 'asc' ? aValue - bValue : bValue - aValue;
    });
  };

  const handleSort = (field: 'revenue' | 'orders') => {
    setSortField(field);
    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    setInfluencers(prevInfluencers => sortInfluencers(prevInfluencers, field, sortDirection === 'asc' ? 'desc' : 'asc'));
  };

  const InfluencerRow = ({ index, style }: { index: number, style: React.CSSProperties }) => {
    const influencer = influencers[index];
    if (!influencer) return null;

    return (
      <div style={style} className="px-6 py-4 hover:bg-gray-50 transition-colors duration-150">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a href={`https://instagram.com/${influencer.igHandle}`} target="_blank" rel="noopener noreferrer" className="flex-shrink-0">
              <FaInstagram className="h-8 w-8 text-pink-500 hover:text-pink-600 transition-colors duration-200" />
            </a>
            <div className="ml-4">
              <h4 className="text-lg font-semibold text-gray-800">{influencer.igHandle}</h4>
              <p className="text-sm text-gray-500">{influencer.firstName}</p>
            </div>
          </div>
          <div className="flex items-center space-x-6">
            <div className="relative group">
              <a href={`mailto:${influencer.email}`} className="flex items-center">
                <FaEnvelope className="h-5 w-5 text-blue-500 hover:text-blue-600 transition-colors duration-200" />
              </a>
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 mb-2 whitespace-nowrap">
                {influencer.email}
              </span>
            </div>
            <div className="flex items-center">
              <FaDollarSign className="h-5 w-5 text-green-500 mr-2" />
              <span className="text-sm font-medium text-gray-700">
                {influencer.revenue !== null && !isNaN(Number(influencer.revenue)) 
                  ? `$${Number(influencer.revenue).toFixed(2)}` 
                  : 'N/A'}
              </span>
            </div>
            <div className="flex items-center">
              <FaShoppingCart className="h-5 w-5 text-orange-500 mr-2" />
              <span className="text-sm font-medium text-gray-700">
                {influencer.orders !== null && !isNaN(Number(influencer.orders)) 
                  ? Number(influencer.orders) 
                  : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden relative">
      {/* Sorting controls */}
      <div className="px-6 py-3 bg-gray-50 flex justify-end items-center border-b border-gray-200">
        <div className="flex items-center">
          <span className="text-sm font-medium text-gray-700 mr-2">Sort by:</span>
          <div className="flex space-x-4">
            <button
              onClick={() => handleSort('revenue')}
              className={`flex items-center text-sm font-medium ${
                sortField === 'revenue' ? 'text-blue-600' : 'text-gray-600'
              } hover:text-blue-700 transition-colors duration-200`}
            >
              Revenue
              {sortField === 'revenue' && (
                sortDirection === 'asc' ? <FaChevronUp className="ml-1" /> : <FaChevronDown className="ml-1" />
              )}
            </button>
            <button
              onClick={() => handleSort('orders')}
              className={`flex items-center text-sm font-medium ${
                sortField === 'orders' ? 'text-blue-600' : 'text-gray-600'
              } hover:text-blue-700 transition-colors duration-200`}
            >
              Orders
              {sortField === 'orders' && (
                sortDirection === 'asc' ? <FaChevronUp className="ml-1" /> : <FaChevronDown className="ml-1" />
              )}
            </button>
          </div>
        </div>
      </div>

      <List
        height={600} // Adjust based on your needs
        itemCount={influencers.length}
        itemSize={100} // Adjust based on your row height
        width="100%"
        onItemsRendered={({ visibleStopIndex }) => {
          if (visibleStopIndex === influencers.length - 1 && hasMore) {
            setPage(prev => prev + 1);
          }
        }}
      >
        {InfluencerRow}
      </List>

      {loading && <p className="text-center py-4">Loading more influencers...</p>}
      {!hasMore && <p className="text-center py-4">No more influencers to load</p>}
    </div>
  );
};

export default InfluencerDirectory;