import React, { useState } from 'react';
import Navigation from './dashboard/Navigation';
import CampaignCreation from './CampaignCreation';
import CampaignOverview from './CampaignOverview';
import CampaignEnd from './CampaignEnd';
import { motion, AnimatePresence } from 'framer-motion';

const CampaignPage: React.FC = () => {
    const [view, setView] = useState<'overview' | 'create' | 'end'>('overview');
    const [campaignData, setCampaignData] = useState<Record<string, any> | null>(null);
  
    const handleCampaignSubmit = (data: Record<string, any>) => {
      setCampaignData(data);
      setView('end');
    };

    const handleBackToCreation = () => {
      setView('create');
    };

    return (
      <div className="min-h-screen bg-gray-50">
        <Navigation />
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold text-gray-900">Campaigns</h1>
              {view === 'overview' && (
                <button
                  onClick={() => setView('create')}
                  className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create Campaign
                </button>
              )}
            </div>
            <CampaignOverview />
            <AnimatePresence>
              {view === 'create' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
                >
                  <CampaignCreation onSubmit={handleCampaignSubmit} />
                </motion.div>
              )}
              {view === 'end' && campaignData && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
                >
                  <CampaignEnd 
                    campaignData={campaignData} 
                    onBack={handleBackToCreation}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </main>
      </div>
    );
  };

export default CampaignPage;