import React from 'react';
import Navigation from '../../components/dashboard/Navigation';
import InfluencerDirectory from '../../components/dashboard/InfluencerDirectory';

const Influencers: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900 mb-6">Influencers</h1>
        <InfluencerDirectory />
      </main>
    </div>
  );
};

export default Influencers;