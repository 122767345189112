import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { FaChartLine } from 'react-icons/fa';
import { format, addMonths } from 'date-fns';
import {
  CHART_COLORS,
  formatDate,
  yAxisTickFormatter,
  responsiveContainerProps,
  tooltipStyle,
  chartMargin,
} from '../../utils/chartHelpers';
import useDashboardData from '../../hooks/useDashboardData';

interface RevenueData {
  month: string;
  revenue: number;
}

interface RevenueChartProps {
  data: RevenueData[];
}

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={tooltipStyle}>
        <p className="font-semibold text-gray-700">{formatDate(label)}</p>
        <p style={{ color: CHART_COLORS.primary }}>
          {`Revenue: $${yAxisTickFormatter(payload[0].value)}`}
        </p>
      </div>
    );
  }
  return null;
};

const RevenueChart: React.FC = () => {
  const { revenueData } = useDashboardData();

  const extendedData = React.useMemo(() => {
    if (revenueData.length === 0) {
      // If no data, return an empty array
      return [];
    }

    // Sort the data by month
    const sortedData = [...revenueData].sort((a, b) => a.month.localeCompare(b.month));

    // Get the first and last month from the actual data
    const firstMonth = new Date(sortedData[0].month);
    const lastMonth = new Date(sortedData[sortedData.length - 1].month);

    // Create an array of all months between the first and last month
    const allMonths = [];
    let currentMonth = firstMonth;
    while (currentMonth <= lastMonth) {
      allMonths.push(format(currentMonth, 'yyyy-MM-dd'));
      currentMonth = addMonths(currentMonth, 1);
    }

    // Fill in any missing months with zero revenue
    const filledData = allMonths.map(month => {
      const existingData = sortedData.find(d => d.month === month);
      if (existingData) {
        const revenue = typeof existingData.revenue === 'number' 
          ? existingData.revenue 
          : parseFloat(existingData.revenue) || 0;
        return { month, revenue };
      }
      return { month, revenue: 0 };
    });

    // Add three future months with zero revenue
    const futureMonths = Array.from({ length: 3 }, (_, i) => ({
      month: format(addMonths(lastMonth, i + 1), 'yyyy-MM-dd'),
      revenue: 0,
    }));

    return [...filledData, ...futureMonths];
  }, [revenueData]);

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium text-gray-700">Monthly Revenue</h2>
          <FaChartLine className="h-5 w-5 text-gray-500" />
        </div>

        {extendedData.length > 0 ? (
          <ResponsiveContainer {...responsiveContainerProps} height={300}>
            <AreaChart data={extendedData} margin={chartMargin}>
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis 
                dataKey="month" 
                tickFormatter={(value) => format(new Date(value), 'MMM yyyy')}
                stroke="#6B7280"
                interval={0}
                angle={-45}
                textAnchor="end"
                height={50}
                tick={{ fontSize: 11 }}
              />
              <YAxis 
                tickFormatter={(value) => `$${yAxisTickFormatter(value)}`} 
                stroke="#6B7280"
                tick={{ fontSize: 11 }} 
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={36} />
              <Area 
                type="monotone" 
                dataKey="revenue" 
                stroke={CHART_COLORS.primary} 
                fill={CHART_COLORS.primaryLight} 
                name="Revenue"
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <div className="flex justify-center items-center h-[300px] text-gray-500">
            No revenue data available
          </div>
        )}
      </div>
    </div>
  );
};

export default RevenueChart;