import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthSuccess: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('access_token');
    const shop = queryParams.get('shop');

    if (accessToken && shop) {
      localStorage.setItem('shopify_access_token', accessToken);
      localStorage.setItem('shopify_shop', shop);
      navigate('/dashboard');
    } else {
      navigate('/', { state: { error: 'Authentication failed' } });
    }
  }, [location, navigate]);

  return <div>Authenticating...</div>;
};

export default AuthSuccess;