import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { startOfMonth, format } from 'date-fns';

interface DashboardData {
  influencerCount: number;
  contactedCount: number;
  interestedCount: number;
  monthlyBarData: Array<{ month: string; contacted: number; interested: number }>;
}

interface DashboardContextType {
  data: DashboardData;
  loading: boolean;
  error: string | null;
  reloadData: () => Promise<void>;
}

const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = useState<DashboardData>({
    influencerCount: 0,
    contactedCount: 0,
    interestedCount: 0,
    monthlyBarData: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('http://localhost:5001/influencers/PrimalDerm');
      if (!response.ok) {
        throw new Error('Failed to fetch dashboard data');
      }
      const result = await response.json();
      if (result.success && Array.isArray(result.influencers)) {
        const influencers = result.influencers;
        const influencerCount = influencers.length;
        const contactedCount = influencers.filter((influencer: any) => 
          influencer.contacted && influencer.contacted !== ""
        ).length;
        const interestedCount = influencers.filter((influencer: any) => 
          influencer.interested === 'interested'
        ).length;

        const monthlyData: { [key: string]: { contacted: number; interested: number } } = {};
        influencers.forEach((influencer: any) => {
          if (influencer.contacted) {
            const monthKey = format(startOfMonth(new Date(influencer.contacted)), 'yyyy-MM-dd');
            if (!monthlyData[monthKey]) {
              monthlyData[monthKey] = { contacted: 0, interested: 0 };
            }
            monthlyData[monthKey].contacted++;
            if (influencer.interested === 'interested') {
              monthlyData[monthKey].interested++;
            }
          }
        });

        const monthlyBarData = Object.entries(monthlyData)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([month, data]) => ({ month, ...data }));

        setData({
          influencerCount,
          contactedCount,
          interestedCount,
          monthlyBarData,
        });
      } else {
        throw new Error('Unexpected data structure');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const reloadData = useCallback(() => fetchDashboardData(), [fetchDashboardData]);

  return (
    <DashboardContext.Provider value={{ data, loading, error, reloadData }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};