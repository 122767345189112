import React, { useState, useEffect } from 'react';
import { FaUser, FaEnvelope, FaBuilding, FaGlobe, FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';
import Navigation from './dashboard/Navigation';

interface ProfileData {
  brandName: string;
  email: string;
  website: string;
  industry: string;
  instagram: string;
  facebook: string;
  twitter: string;
}

const ProfilePage: React.FC = () => {
  const [profile, setProfile] = useState<ProfileData>({
    brandName: '',
    email: '',
    website: '',
    industry: '',
    instagram: '',
    facebook: '',
    twitter: '',
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Fetch profile data from API
    // For now, we'll use mock data
    setProfile({
      brandName: 'Your Brand Name',
      email: 'yourbrand@example.com',
      website: 'https://yourbrand.com',
      industry: 'Fashion',
      instagram: 'yourbrand',
      facebook: 'yourbrand',
      twitter: 'yourbrand',
    });
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the updated profile to your backend
    console.log('Updated profile:', profile);
    setIsEditing(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Your Profile</h1>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Brand Information</h3>
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isEditing ? 'Cancel' : 'Edit'}
              </button>
            </div>
            <div className="border-t border-gray-200">
              <form onSubmit={handleSubmit}>
                <dl>
                  {Object.entries(profile).map(([key, value]) => (
                    <div key={key} className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500 flex items-center">
                        {getIcon(key)}
                        <span className="ml-2">{formatLabel(key)}</span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {isEditing ? (
                          <input
                            type="text"
                            name={key}
                            value={value}
                            onChange={handleInputChange}
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                        ) : (
                          value
                        )}
                      </dd>
                    </div>
                  ))}
                </dl>
                {isEditing && (
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const getIcon = (key: string) => {
  switch (key) {
    case 'brandName':
      return <FaBuilding className="h-5 w-5 text-gray-400" />;
    case 'email':
      return <FaEnvelope className="h-5 w-5 text-gray-400" />;
    case 'website':
      return <FaGlobe className="h-5 w-5 text-gray-400" />;
    case 'industry':
      return <FaUser className="h-5 w-5 text-gray-400" />;
    case 'instagram':
      return <FaInstagram className="h-5 w-5 text-gray-400" />;
    case 'facebook':
      return <FaFacebook className="h-5 w-5 text-gray-400" />;
    case 'twitter':
      return <FaTwitter className="h-5 w-5 text-gray-400" />;
    default:
      return null;
  }
};

const formatLabel = (key: string) => {
  return key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
};

export default ProfilePage;