import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import LandingPage from './components/LandingPage';
import AuthCallback from './components/AuthCallback';
import Dashboard from './components/Dashboard';
import CampaignCreation from './components/CampaignCreation';
import EmailTemplate from './components/EmailTemplate';
import DashboardClient from './components/dashboard/DashboardClient';
import { initializeApp, getApps, FirebaseOptions } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { app } from './components/firebase';
import AuthSuccess from './components/AuthSuccess';
import ThankYou from './components/ThankYou';
import LoginPage from './components/LoginPage';
import ShopifyCallback from './components/shopifyCallback';
import InfluencerDirectory from './components/influencerdir/InfluencerDirectory';
import { DashboardProvider } from './contexts/DashboardContext';
import ProfilePage from './components/ProfilePage';
import CampaignPage from './components/CampaignPage'
import CampaignEnd from './components/CampaignEnd';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#B39DDB',
    },
    background: {
      default: '#000000',
      paper: '#121212',
    },
  },
});

const FirebaseInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeFirebase = async () => {
      try {
        await initializeApp(firebaseConfig);
        console.log('Firebase initialized successfully');
        setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing Firebase:', error);
        setIsInitialized(true); // Set to true even on error to allow rendering
      }
    };

    initializeFirebase();
  }, []);

  if (!isInitialized) {
    return <div>Initializing Firebase...</div>;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <DashboardProvider>
      <FirebaseInitializer>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/dashboard" element={<AuthSuccess />} />
              <Route path="/campaign" element={<CampaignCreation onSubmit={() => {}} />} />
              <Route path="/email-template" element={<EmailTemplate />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/dashboard-client" element={<DashboardClient />} />
              <Route path="/shopify/callback" element={<ShopifyCallback />} />
              <Route path="/influencers" element={<InfluencerDirectory />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/campaigns" element={<CampaignPage />} />
              <Route path="/campaign-end" element={<CampaignEnd campaignData={{}} />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </FirebaseInitializer>
    </DashboardProvider>
  );
};

export default App;
export { FirebaseInitializer };