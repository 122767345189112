import React, { useEffect, useState } from 'react';
import { FaUsers, FaChartLine, FaHandshake } from 'react-icons/fa';
import Navigation from './Navigation';
import CampaignOverview from './CampaignOverview';
import BarterdealStatus from './BarterdealStatus';
import RevenueChart from './RevenueChart';
import AvgRevenuePerCollabChart from './AvgRevenuePerCollabChart';
import useDashboardData from '../../hooks/useDashboardData';
import StatCard from './StatCard';

// Add this type definition at the top of the file
type StatCardValue = number | string | null;

const DashboardClient: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    influencerCount,
    contactedCount,
    interestedCount,
    campaignData,
    revenueData,
    avgRevenuePerCollabData,
    fetchInfluencerCount
  } = useDashboardData();

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfluencerCount();
      setIsLoading(false);
    };
    fetchData();
  }, [fetchInfluencerCount]);

  const calculatePercentage = (count: number, total: number) => {
    return total > 0 ? Math.round((count / total) * 100) : 0;
  };

  const getInterestedValue = () => {
    if (isLoading) return '-';
    if (influencerCount === 0) return '0%';
    return `${calculatePercentage(interestedCount, influencerCount)}%`;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <StatCard
            icon={FaUsers}
            title="Total Influencers"
            value={isLoading ? null : influencerCount as StatCardValue}

          />
          <StatCard
            icon={FaChartLine}
            title="Contacted"
            value={isLoading ? null : contactedCount as StatCardValue}

          />
          <StatCard
            icon={FaHandshake}
            title="Interested"
            value={getInterestedValue() as StatCardValue}
          />
        </div>

        {!isLoading && (
          <>
            <div className="mt-8 grid grid-cols-1 gap-5 lg:grid-cols-2">
              <CampaignOverview data={campaignData} />
              <BarterdealStatus />
            </div>
            <div className="mt-8 grid grid-cols-1 gap-5 lg:grid-cols-2">
              <RevenueChart />
              <AvgRevenuePerCollabChart data={avgRevenuePerCollabData} />
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default DashboardClient;