import { useState, useEffect, useCallback } from 'react';
import { MonthlyData, InfluencerData } from '../types/dashboardTypes';
import { processInfluencerData } from '../utils/dashboardUtils';
import { startOfMonth, format, parse } from 'date-fns';

// Add this interface definition
interface CampaignData {
  month: string;
  contacted: number;
  interested: number;
}

// Add these interfaces
interface RevenueData {
  month: string;
  revenue: number;
}

interface AvgRevenuePerCollabData {
  month: string;
  avgRevenue: number;
}

interface InfluencerStatusData {
  name: string;
  value: number;
}

const useDashboardData = () => {
    const [influencerCount, setInfluencerCount] = useState<number>(0);
    const [showMonthlyView, setShowMonthlyView] = useState(false);
    const [monthlyData, setMonthlyData] = useState<MonthlyData[]>([
        { month: 'Jan 2023', count: 50 },
        { month: 'Feb 2023', count: 75 },
        { month: 'Mar 2023', count: 100 },
    ]);
    const [campaignData, setCampaignData] = useState<CampaignData[]>([]);

    const [contactedCount, setContactedCount] = useState<number>(0);
    const [interestedCount, setInterestedCount] = useState<number>(0);
    const [monthlyBarData, setMonthlyBarData] = useState<Array<{ month: string; contacted: number; interested: number }>>([]);

    const [revenueData, setRevenueData] = useState<RevenueData[]>([]);
    const [avgRevenuePerCollabData, setAvgRevenuePerCollabData] = useState<AvgRevenuePerCollabData[]>([]);
    const [influencerStatusData, setInfluencerStatusData] = useState<InfluencerStatusData[]>([]);

    const fetchInfluencerCount = useCallback(async () => {
        try {
            const brandName = localStorage.getItem('brandName');
            if (!brandName) {
                throw new Error('Brand name not found. Please log in again.');
            }

            console.log('Fetching influencer count...');
            const response = await fetch(`http://92.113.25.172:5001/influencers/${brandName}`);
            if (!response.ok) {
                throw new Error('Failed to fetch influencer count');
            }
            const data = await response.json();
            if (data.success && Array.isArray(data.influencers)) {
                console.log('Influencer count fetched:', data.influencers.length);
                setInfluencerCount(data.influencers.length);
                
                // Calculate contacted and interested counts
                const contacted = data.influencers.filter((influencer: any) => 
                    influencer.contacted && influencer.contacted !== ""
                ).length;
                setContactedCount(contacted);

                const interested = data.influencers.filter((influencer: any) => 
                    influencer.interested === 'interested'
                ).length;
                setInterestedCount(interested);

                // Calculate campaign data
                const monthlyData: { [key: string]: CampaignData } = {};
                data.influencers.forEach((influencer: any) => {
                    if (influencer.contacted) {
                        const monthKey = format(startOfMonth(new Date(influencer.contacted)), 'yyyy-MM-dd');
                        if (!monthlyData[monthKey]) {
                            monthlyData[monthKey] = { month: monthKey, contacted: 0, interested: 0 };
                        }
                        monthlyData[monthKey].contacted++;
                        if (influencer.interested === 'interested') {
                            monthlyData[monthKey].interested++;
                        }
                    }
                });

                const sortedCampaignData = Object.values(monthlyData).sort((a, b) => a.month.localeCompare(b.month));
                setCampaignData(sortedCampaignData);

                // Calculate revenue data
                const revenueByMonth: { [key: string]: number } = {};
                data.influencers.forEach((influencer: any) => {
                    if (influencer.revenue !== undefined && influencer.contacted) {
                        const monthKey = format(startOfMonth(new Date(influencer.contacted)), 'yyyy-MM-dd');
                        const revenue = parseFloat(influencer.revenue) || 0;
                        revenueByMonth[monthKey] = (revenueByMonth[monthKey] || 0) + revenue;
                    }
                });

                const sortedRevenueData = Object.entries(revenueByMonth)
                    .map(([month, revenue]) => ({ month, revenue: Number(revenue.toFixed(2)) }))
                    .sort((a, b) => a.month.localeCompare(b.month));

                setRevenueData(sortedRevenueData);

                // Calculate average revenue per collaboration
                const avgRevenueByMonth: { [key: string]: { total: number; count: number } } = {};
                data.influencers.forEach((influencer: any) => {
                    if (influencer.revenue !== undefined && influencer.revenue !== null && influencer.contacted) {
                        const monthKey = format(startOfMonth(new Date(influencer.contacted)), 'yyyy-MM-dd');
                        const revenue = parseFloat(influencer.revenue) || 0;
                        if (!avgRevenueByMonth[monthKey]) {
                            avgRevenueByMonth[monthKey] = { total: 0, count: 0 };
                        }
                        avgRevenueByMonth[monthKey].total += revenue;
                        avgRevenueByMonth[monthKey].count += 1;
                    }
                });

                const sortedAvgRevenueData = Object.entries(avgRevenueByMonth)
                    .map(([month, { total, count }]) => ({
                        month,
                        avgRevenue: count > 0 ? Number((total / count).toFixed(2)) : 0
                    }))
                    .sort((a, b) => a.month.localeCompare(b.month));

                setAvgRevenuePerCollabData(sortedAvgRevenueData);

                // Calculate influencer status data, excluding blank or null statuses
                const statusCounts: { [key: string]: number } = {};
                data.influencers.forEach((influencer: any) => {
                    const status = influencer.status;
                    if (status && status.trim() !== '') {
                        statusCounts[status] = (statusCounts[status] || 0) + 1;
                    }
                });

                const statusData = Object.entries(statusCounts)
                    .map(([name, value]) => ({ name, value }))
                    .sort((a, b) => b.value - a.value); // Sort by value in descending order
                setInfluencerStatusData(statusData);

                // Process monthly bar data
                const sortedMonthlyData = Object.entries(monthlyData)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([monthKey, { contacted, interested }]) => ({
                        month: format(parse(monthKey, 'yyyy-MM-dd', new Date()), 'MMM yyyy'),
                        contacted,
                        interested
                    }));

                setMonthlyBarData(sortedMonthlyData);
            } else {
                console.error('Unexpected data structure:', data);
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            console.error('Error fetching influencer count:', error);
        }
    }, []);

    const reloadData = useCallback(async () => {
        await fetchInfluencerCount();
    }, [fetchInfluencerCount]);

    useEffect(() => {
        console.log('Initial data load');
        reloadData();
    }, [reloadData]);

    return {
        influencerCount,
        contactedCount,
        interestedCount,
        showMonthlyView,
        setShowMonthlyView,
        monthlyData,
        campaignData,
        reloadData,
        fetchInfluencerCount,
        monthlyBarData,
        revenueData,
        avgRevenuePerCollabData,
        influencerStatusData
    };
};

export default useDashboardData;