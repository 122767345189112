import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { motion } from 'framer-motion';

interface ProgressStep {
  name: string;
  status: 'completed' | 'current' | 'upcoming';
}

const steps: ProgressStep[] = [
  { name: 'On-boarding', status: 'completed' },
  { name: 'Curating List', status: 'completed' },
  { name: 'Contacting', status: 'current' },
  { name: 'Negotiations/Posts', status: 'upcoming' },
];

const ProgressIndicator: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  const getStepColor = (status: ProgressStep['status']) => {
    switch (status) {
      case 'completed':
        return 'bg-green-500 text-white';
      case 'current':
        return 'bg-blue-500 text-white';
      default:
        return 'bg-gray-200 text-gray-500';
    }
  };

  const getStepTextColor = (status: ProgressStep['status']) => {
    switch (status) {
      case 'completed':
        return 'text-green-500';
      case 'current':
        return 'text-blue-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg p-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium text-gray-900">Client Progress</h3>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-gray-500 hover:text-gray-700"
        >
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      {isOpen && (
        <div className="flex justify-between items-center relative">
          {steps.map((step, index) => (
            <div key={step.name} className="flex flex-col items-center z-10">
              <div className={`w-10 h-10 rounded-full flex items-center justify-center mb-1 ${getStepColor(step.status)}`}>
                {step.status === 'completed' ? '✓' : index + 1}
              </div>
              <span className={`text-xs text-center ${getStepTextColor(step.status)} font-semibold`}>
                {step.name}
              </span>
              {step.status === 'current' && (
                <motion.div
                  className="w-2 h-2 bg-blue-500 rounded-full mt-1"
                  animate={{ opacity: [0, 1, 0] }}
                  transition={{ duration: 1.5, repeat: Infinity }}
                />
              )}
            </div>
          ))}
          <div className="absolute top-5 left-0 right-0 h-0.5 bg-gray-200 -z-10" />
          <div 
            className="absolute top-5 left-0 h-0.5 bg-blue-500 -z-10" 
            style={{
              width: `${((steps.findIndex(step => step.status === 'current') + 1) / steps.length) * 100}%`,
              transition: 'width 0.5s ease-in-out'
            }} 
          />
        </div>
      )}
    </div>
  );
};

export default ProgressIndicator;