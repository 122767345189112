import React, { useState } from 'react';
import { FaUsers, FaTshirt, FaPercent, FaDollarSign, FaTimes, FaGlobeAmericas } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

interface CampaignData {
  creatorCount: number;
  selectedProduct: string;
  discountType: '$' | '%';
  discountValue: number;
  selectedCountry: string;
}

interface CampaignCreationProps {
  onSubmit: (data: CampaignData) => void;
}

const CampaignCreation: React.FC<CampaignCreationProps> = ({ onSubmit }) => {
  const navigate = useNavigate();

  const [creatorCount, setCreatorCount] = useState<number>(1000);
  const [selectedProduct, setSelectedProduct] = useState<string>('Hoodie ($39.99)');
  const [discountType, setDiscountType] = useState<'$' | '%'>('%');
  const [discountValue, setDiscountValue] = useState<number>(20);
  const [selectedCountry, setSelectedCountry] = useState<string>('United States');

  const productOptions = [
    "T-Shirt ($19.99)",
    "Hoodie ($39.99)",
    "Sneakers ($79.99)",
  ];

  const countryOptions = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    // Add more countries as needed
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const campaignData = {
      creatorCount,
      selectedProduct,
      discountType,
      discountValue,
      selectedCountry
    };
    onSubmit(campaignData);
    navigate('/campaign-end', { state: { campaignData } });
  };


  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-md card-glow relative"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Create New Campaign</h2>
      
      <div className="flex justify-center space-x-2 mb-6">
        {[1, 2].map((step) => (
          <div key={step} className={`w-8 h-8 rounded-full flex items-center justify-center ${step === 1 ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'} font-bold text-sm`}>
            {step}
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="creatorCount" className="block text-sm font-medium text-gray-700 mb-1">
            Number of Creators
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaUsers className="h-5 w-5 text-gray-400" />
            </div>
            <input
              id="creatorCount"
              type="number"
              value={creatorCount}
              onChange={(e) => setCreatorCount(parseInt(e.target.value))}
              className="appearance-none text-gray-700 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter number of creators"
            />
          </div>
        </div>

        <div>
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
            Country
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaGlobeAmericas className="h-5 w-5 text-gray-400" />
            </div>
            <select
              id="country"
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              className="appearance-none text-gray-700 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {countryOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label htmlFor="product" className="block text-sm font-medium text-gray-700 mb-1">
            Product
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaTshirt className="h-5 w-5 text-gray-400" />
            </div>
            <select
              id="product"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              className="appearance-none text-gray-700 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {productOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="flex-1">
            <label htmlFor="discountValue" className="block text-sm font-medium text-gray-700 mb-1">
              Discount Value
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {discountType === '%' ? <FaPercent className="h-5 w-5 text-gray-400" /> : <FaDollarSign className="h-5 w-5 text-gray-400" />}
              </div>
              <input
                id="discountValue"
                type="number"
                value={discountValue}
                onChange={(e) => setDiscountValue(parseFloat(e.target.value))}
                className="appearance-none text-gray-700 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter discount value"
              />
            </div>
          </div>
          <div>
            <label htmlFor="discountType" className="block text-sm font-medium text-gray-700 mb-1">
              Type
            </label>
            <div className="flex">
              <button
                type="button"
                onClick={() => setDiscountType('%')}
                className={`flex items-center justify-center px-4 py-2 border ${
                  discountType === '%' ? 'border-indigo-500 bg-indigo-50 text-indigo-600' : 'border-gray-300 bg-white text-gray-700'
                } rounded-l-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <FaPercent className="h-5 w-5" />
              </button>
              <button
                type="button"
                onClick={() => setDiscountType('$')}
                className={`flex items-center justify-center px-4 py-2 border ${
                  discountType === '$' ? 'border-indigo-500 bg-indigo-50 text-indigo-600' : 'border-gray-300 bg-white text-gray-700'
                } rounded-r-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <FaDollarSign className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transform transition hover:scale-105"
        >
          Create Campaign
        </button>
      </form>

      <div className="mt-6 text-center">
        <p className="text-sm font-medium text-gray-700">Next steps: Campaign Approval and Overview</p>
        <p className="text-xs text-gray-500 mt-1">Review campaign details, expected sales, and approve for launch in the next step.</p>
      </div>
    </motion.div>
  );
};

export default CampaignCreation;