import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EmailTemplate: React.FC = () => {
    const [selectedTemplate, setSelectedTemplate] = useState<string>('welcome');
    const [emailContent, setEmailContent] = useState<string>('');
    const [comments, setComments] = useState<string>('');
    const [approvalStep, setApprovalStep] = useState<number>(3);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [creationStep, setCreationStep] = useState<string>('');

    const templates = {
        welcome: "Welcome to our community! We're excited to have you on board.",
        reminder: "Don't forget about your exclusive discount! It expires soon.",
        outreach: "We have an amazing offer just for you. Check it out!",
    };
    useEffect(() => {
        setEmailContent(templates[selectedTemplate as keyof typeof templates]);
    }, [selectedTemplate]);

    const handleCreateCampaign = () => {
        setIsCreating(true);
        const steps = ['Creating Email Templates', 'Setting Up Email Reminders', 'Preparing Email Outreach'];
        let currentStep = 0;

        const interval = setInterval(() => {
            if (currentStep < steps.length) {
                setCreationStep(steps[currentStep]);
                currentStep++;
            } else {
                clearInterval(interval);
                setIsCreating(false);
            }
        }, 1667); // 5 seconds / 3 steps ≈ 1667ms per step
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100 flex items-center justify-center p-4">
            <div className="bg-white rounded-3xl shadow-2xl p-8 w-full max-w-4xl">
                <div className="flex justify-center space-x-2 mb-8">
                    {[1, 2, 3, 4, 5].map((step) => (
                        <div
                            key={step}
                            className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-semibold
                                ${step === approvalStep ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'}`}
                        >
                            {step}
                        </div>
                    ))}
                </div>
                <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Email Template Approval</h1>
                <div className="space-y-6">
                    <div>
                        <label htmlFor="template" className="block text-sm font-medium text-gray-700 mb-2">Select Template</label>
                        <select
                            id="template"
                            value={selectedTemplate}
                            onChange={(e) => setSelectedTemplate(e.target.value)}
                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 p-2.5"
                        >
                            <option value="welcome">Welcome Email</option>
                            <option value="reminder">Reminder Email</option>
                            <option value="outreach">Outreach Email</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="emailContent" className="block text-sm font-medium text-gray-700 mb-2">Edit Email Content</label>
                        <ReactQuill theme="snow" value={emailContent} onChange={setEmailContent} />
                    </div>
                    <div>
                        <label htmlFor="comments" className="block text-sm font-medium text-gray-700 mb-2">Comments</label>
                        <textarea
                            id="comments"
                            rows={4}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 p-2.5"
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="space-x-2">
                            <button
                                onClick={() => setApprovalStep((prev) => Math.min(prev + 1, 4))}
                                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                            >
                                {approvalStep === 5 ? 'Finish' : 'Approve'}
                            </button>
                            <button
                                onClick={() => setApprovalStep((prev) => Math.max(prev - 1, 1))}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
                            >
                                {approvalStep === 1 ? 'Cancel' : 'Back'}
                            </button>
                        </div>
                        <span className="text-sm text-gray-600">Approval Step: {approvalStep}/5</span>
                    </div>
                    <div className="text-center">

                        {isCreating && (
                            <p className="mt-2 text-sm text-gray-600">{creationStep}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailTemplate;