import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { FaChartPie } from 'react-icons/fa';
import { TooltipProps } from 'recharts';
import useDashboardData from '../../hooks/useDashboardData';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 border border-gray-200 rounded shadow">
          <p className="font-semibold text-gray-800">{`${data.name}: ${data.value}`}</p>
        </div>
      );
    }
    return null;
  };

const BarterdealStatus: React.FC = () => {
  const { influencerStatusData } = useDashboardData();

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium text-gray-900">Barterdeal Status</h2>
          <FaChartPie className="h-5 w-5 text-gray-400" />
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={influencerStatusData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {influencerStatusData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
        <div className="mt-4 grid grid-cols-2 gap-4">
          {influencerStatusData.map((entry, index) => (
            <div key={entry.name} className="flex items-center">
              <span 
                className="w-4 h-4 rounded-full mr-2"
                style={{ backgroundColor: COLORS[index % COLORS.length] }}
              ></span>
              <span className="text-sm text-gray-600">{entry.name}</span>
              <span className="ml-auto text-sm font-medium text-gray-900">{entry.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BarterdealStatus;