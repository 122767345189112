import React, { useState } from 'react';
import '../styles/AnimatedBackground.css';
import logo from '../assets/logo.png';
import { FaShoppingBag, FaTag, FaChartLine, FaBoxOpen, FaEnvelope, FaBuilding, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { handleAuth } from './api';

const LandingPage: React.FC = () => {
  const [shopName, setShopName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      console.log('Submitting form with data:', { email, password, shopName, companyName, isSignUp });
      const result = await handleAuth(email, password, shopName, companyName, isSignUp);
      if (result.authUrl) {
        window.location.href = result.authUrl;
      } else {
        throw new Error('No auth URL provided');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 animated-bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-6 px-4 shadow sm:rounded-2xl sm:px-10 card-glow relative">
          <div className="text-center pb-4">
            <img src={logo} alt="BarterAI Logo" className="mx-auto h-16" />
          </div>
          <h2 className="mb-4 text-center text-xl font-bold text-gray-900">
            {isSignUp ? 'Sign Up for BarterAI' : 'Login to BarterAI'}
          </h2>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="shopName" className="block text-sm font-medium text-gray-700 mb-1">
                Shop Name
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <FaShoppingBag className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="shopName"
                  name="shopName"
                  type="text"
                  required
                  className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  placeholder="Enter your shop name"
                  value={shopName}
                  onChange={(e) => setShopName(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                E-mail
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <FaEnvelope className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className=" text-black appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1">
                Company Name
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <FaBuilding className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="companyName"
                  name="companyName"
                  type="text"
                  required
                  className="text-black appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <FaLock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  required
                  className="text-black appearance-none block w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  >
                    {showPassword ? <FaEyeSlash className="h-5 w-5" /> : <FaEye className="h-5 w-5" />}
                  </button>
                </div>
              </div>
            </div>

            {error && (
              <div className="text-red-600 text-sm mt-2">
                {error}
              </div>
            )}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transform transition hover:scale-105"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : (isSignUp ? 'Sign Up' : 'Login')}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Required permissions</span>
              </div>
            </div>

            <div className="mt-4">
              <ul className="space-y-2">
                <li className="flex items-center text-sm text-gray-600">
                  <FaTag className="mr-2 text-indigo-500" />
                  Create discount codes
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <FaChartLine className="mr-2 text-indigo-500" />
                  Read revenue
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <FaBoxOpen className="mr-2 text-indigo-500" />
                  See your products
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="mt-4 text-center text-sm text-white">
          {isSignUp ? 'Already have an account?' : 'New to our app?'}{' '}
          <button
            onClick={() => setIsSignUp(!isSignUp)}
            className="font-medium text-indigo-200 hover:text-indigo-100 underline"
          >
            {isSignUp ? 'Login' : 'Create an account'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default LandingPage;