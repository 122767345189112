import React from 'react';
import { IconType } from 'react-icons';

type StatCardValue = number | string | null;

interface StatCardProps {
  icon: IconType;
  title: string;
  value: StatCardValue;
}

const StatCard: React.FC<StatCardProps> = ({ icon: Icon, title, value}) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <Icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
              <dd>
                {value !== null ? (
                  <div className="text-lg font-medium text-gray-900">{value}</div>
                ) : (
                  <div className="animate-pulse">
                    <div className="h-6 w-20 bg-gray-200 rounded"></div>
                  </div>
                )}
              </dd>
            </dl>
          </div>
        </div>
      </div>

    </div>
  );
};

export default StatCard;