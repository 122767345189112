import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { FaChartBar } from 'react-icons/fa';
import { addMonths, format } from 'date-fns';
import {
  CHART_COLORS,
  formatDate,
  yAxisTickFormatter,
  responsiveContainerProps,
  tooltipStyle,
  chartMargin,
} from '../../utils/chartHelpers';

interface CampaignData {
  month: string;
  contacted: number;
  interested: number;
}

interface CampaignOverviewProps {
  data: CampaignData[];
}

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={tooltipStyle}>
        <p className="font-semibold text-gray-700">{formatDate(label)}</p>
        {payload.map((entry: any, index: number) => (
          <p key={index} style={{ color: entry.color }}>
            {`${entry.name}: ${yAxisTickFormatter(entry.value)}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const CampaignOverview: React.FC<CampaignOverviewProps> = ({ data }) => {
  const extendedData = React.useMemo(() => {
    const lastMonth = new Date(data[data.length - 1].month);
    const futureMonths = Array.from({ length: 3 }, (_, i) => {
      const futureMonth = addMonths(lastMonth, i + 1);
      return {
        month: format(futureMonth, 'yyyy-MM-dd'),
        contacted: 0,
        interested: 0,
      };
    });
    return [...data, ...futureMonths];
  }, [data]);

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium text-gray-700">Campaign Status Overview</h2>
          <FaChartBar className="h-5 w-5 text-gray-500" />
        </div>

        <ResponsiveContainer {...responsiveContainerProps} height={300}>
          <BarChart data={extendedData} margin={chartMargin}>
            <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
            <XAxis 
              dataKey="month" 
              tickFormatter={(value) => format(new Date(value), 'MMM yyyy')}
              stroke="#6B7280"
              interval={0}
              angle={-45}
              textAnchor="end"
              height={50}
              tick={{ fontSize: 11 }}  // Reduced font size here
            />
            <YAxis 
              tickFormatter={yAxisTickFormatter} 
              stroke="#6B7280"
              tick={{ fontSize: 11 }} 
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" height={36} />
            <Bar 
              dataKey="contacted" 
              fill={CHART_COLORS.primary} 
              name="Contacted"
              barSize={20}
            />
            <Bar 
              dataKey="interested" 
              fill={CHART_COLORS.secondary} 
              name="Interested"
              barSize={20}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CampaignOverview;