import { format, parseISO } from 'date-fns';

// Color palette for charts
export const CHART_COLORS = {
  primary: '#6366F1',
  primaryLight: '#818CF8', // Add this line
  secondary: '#10B981',
  tertiary: '#F59E0B',
  quaternary: '#EF4444',
  gray: '#6B7280',
};

// Gradient definitions for area charts
export const getAreaGradient = (color: string, id: string): string => `
  <linearGradient id="${id}" x1="0" y1="0" x2="0" y2="1">
    <stop offset="5%" stop-color="${color}" stop-opacity="0.1" />
    <stop offset="95%" stop-color="${color}" stop-opacity="0" />
  </linearGradient>
`;

// Custom tooltip styles
export const tooltipStyle = {
  backgroundColor: 'white',
  border: 'none',
  borderRadius: '8px',
  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  padding: '8px',
};

// Format date for x-axis
export const formatDate = (dateString: string) => {
  try {
    const date = parseISO(dateString);
    return format(date, 'MMM yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return dateString; // Return the original string if parsing fails
  }
};

// Format currency
export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

// Format large numbers with K, M, B suffixes
export const formatLargeNumber = (num: number) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
};

// Custom Y-axis tick formatter
export const yAxisTickFormatter = (value: number) => {
  return formatLargeNumber(value);
};

// Chart responsive container default props
export const responsiveContainerProps = {
  width: '100%',
  height: 300,
};

// Common chart margin
export const chartMargin = { top: 10, right: 30, left: 0, bottom: 0 };

// Pie chart colors
export const PIE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Helper function to get percentage for pie chart
export const getPercentage = (value: number, total: number) => {
  return ((value / total) * 100).toFixed(0) + '%';
};

// Custom legend formatter
export const legendFormatter = (value: string, entry: any): string => {
    return `<span style="color: ${entry.color}; margin-right: 10px;">${value}</span>`;
  };
  
  // Helper function to generate linear gradient for SVG paths
  export const generateLinearGradient = (id: string, color: string): string => `
    <linearGradient id="${id}" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stop-color="${color}" stop-opacity="0.8"/>
      <stop offset="95%" stop-color="${color}" stop-opacity="0"/>
    </linearGradient>
  `;