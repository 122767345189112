export const handleAuth = async (email: string, password: string, shopName: string, companyName: string, isSignUp: boolean) => {
  const endpoint = 'https://09ea-77-119-197-174.ngrok-free.app/api/auth';
  try {
    console.log('Sending auth request with data:', { email, shopName, companyName, isSignUp });
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, shopName, companyName, isSignUp }),
      credentials: 'include',
    });

    const responseText = await response.text();
    console.log('Raw response:', responseText);

    let data;
    try {
      data = JSON.parse(responseText);
    } catch (parseError) {
      console.error('Failed to parse response as JSON:', parseError);
      throw new Error(`Server returned invalid JSON. Raw response: ${responseText}`);
    }

    if (!response.ok) {
      throw new Error(data.error || `Authentication failed with status ${response.status}`);
    }
    
    if (data.sessionData && data.authUrl) {
      // Store session data in localStorage
      localStorage.setItem('sessionData', JSON.stringify(data.sessionData));
      
      // Open Shopify auth URL in a new tab
      window.open(data.authUrl, '_blank');
      
      // Start polling for session completion
      await pollSessionCompletion(data.sessionData.sessionId);
      
      // Session completed, proceed with any additional actions
      console.log('Shopify authorization completed');
      
      // Store brand name in localStorage
      localStorage.setItem('brandName', data.sessionData.shopName);
      
      return data.sessionData;
    } else {
      throw new Error('No session data or auth URL provided');
    }
  } catch (error) {
    console.error('Error during authentication:', error);
    throw error;
  }
};

const pollSessionCompletion = async (sessionId: string, maxAttempts = 60, interval = 5000) => {
  const sessionEndpoint = `https://09ea-77-119-197-174.ngrok-free.app/api/auth/session/${sessionId}`;
  
  for (let i = 0; i < maxAttempts; i++) {
    const response = await fetch(sessionEndpoint);
    if (response.ok) {
      const sessionData = await response.json();
      if (sessionData.completed) {
        // Update local storage with completed session data
        localStorage.setItem('sessionData', JSON.stringify(sessionData));
        return;
      }
    }
    await new Promise(resolve => setTimeout(resolve, interval));
  }
  
  throw new Error('Session completion timeout');
};

// Add a function to retrieve session data
export const getSessionData = () => {
  const sessionData = localStorage.getItem('sessionData');
  return sessionData ? JSON.parse(sessionData) : null;
};

// Add a function to clear session data on logout
export const clearSessionData = () => {
  localStorage.removeItem('sessionData');
};