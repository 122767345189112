import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AnimatedBackground.css';

const ThankYou: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 animated-bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10 card-glow relative">
          <div className="text-center pb-8">
            <h2 className="text-3xl font-extrabold text-gray-900">Thank You!</h2>
            <p className="mt-2 text-sm text-gray-600">
              Thank you for choosing Barter AI. We will send you the next steps shortly. For now, you can go to your dashboard where you will see live updates about your campaigns.
            </p>
          </div>
          <div className="mt-6">
            <button
              onClick={() => navigate('/dashboard-client')}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transform transition hover:scale-105"
            >
              Go to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;